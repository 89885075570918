import styled from 'styled-components';

const Block = styled.div`
  background-color: #fff;
  flex: 1;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  & > *:not(.divider) {
    padding: 14px 24px;
  }

  & h2 {
    font-size: 1.25rem;
    margin: 0;
  }

  & .subtitle {
    font-size: 0.6em;
    color: #b2b2b2;
  }

  & > .divider {
    width: 100%;
    height: 0.5px;
    background-color: #f0f0f0;
  }

  & .field {
    margin-bottom: 15px;
  }

  & .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & > h4 {
      font-weight: 600;
      width: 120px;
      margin: 0;
      flex-shrink: 0;
    }

    & > h4.short {
      width: 70px;
    }

    & > .ant-input,
    .ant-input-group-wrapper,
    .ant-select {
      flex-basis: 300px;
    }

    & > div {
      word-break: break-word;
    }
  }
`;

export default Block;
