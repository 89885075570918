import React from 'react';
import {Button as AntButton} from 'antd';
import styled from 'styled-components';

export default styled(AntButton)`
  background-color: ${(props) => props.color || ''};
  border-color: ${(props) => props.color || ''};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  :active {
    background-color: ${(props) => props.color + '99' || ''};
    border-color: ${(props) => props.color + '66' || ''};
  }
`;
