import React, {useState, useEffect} from 'react';
import {Tree as AntTree} from 'antd';

export default function Tree({
  source,
  onCheckChange,
  initialCheckedKeys,
  readOnly,
}) {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };
  const onCheck = (checkedKeysValue, e) => {
    const {checked, node} = e;
    let newCheckedKeys = new Set(checkedKeysValue.checked);

    if (checked) {
      addParentAndChildrenKeys(node.key, newCheckedKeys);
      addParentKeys(node.key, newCheckedKeys, source);
    } else {
      removeChildrenKeys(node.key, newCheckedKeys);
      removeUnnecessaryParents(node.key, newCheckedKeys, source);
    }

    setCheckedKeys(Array.from(newCheckedKeys));

    if (onCheckChange) {
      onCheckChange(Array.from(newCheckedKeys));
    }
  };

  const addParentAndChildrenKeys = (key, set) => {
    const node = findNodeByKey(source, key);
    if (node) {
      set.add(node.key);
      if (node.children) {
        node.children.forEach((child) =>
          addParentAndChildrenKeys(child.key, set),
        );
      }
    }
  };

  const removeChildrenKeys = (key, set) => {
    const node = findNodeByKey(source, key);
    if (node) {
      set.delete(node.key);
      if (node.children) {
        node.children.forEach((child) => removeChildrenKeys(child.key, set));
      }
    }
  };

  const findNodeByKey = (nodes, key) => {
    for (let node of nodes) {
      if (node.key === key) {
        return node;
      }
      if (node.children) {
        const childNode = findNodeByKey(node.children, key);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  };

  const addParentKeys = (key, set, nodes) => {
    const path = findPathByKey(nodes, key);
    if (path && path.length > 1) {
      path.slice(0, -1).forEach((parentKey) => {
        set.add(parentKey);
      });
    }
  };

  const removeUnnecessaryParents = (key, set, nodes) => {
    const path = findPathByKey(nodes, key);
    if (path && path.length > 1) {
      for (let i = path.length - 2; i >= 0; i--) {
        // 從倒數第二個開始往上找父節點
        const parentNode = findNodeByKey(nodes, path[i]);
        if (
          parentNode &&
          parentNode.children.every((child) => !set.has(child.key))
        ) {
          set.delete(parentNode.key);
        } else {
          break; // 如果找到一個父節點還有被選中的子節點，則停止向上檢查
        }
      }
    }
  };

  const findPathByKey = (nodes, key, path = []) => {
    for (let node of nodes) {
      const currentPath = path.concat(node.key);
      if (node.key === key) {
        return currentPath;
      }
      if (node.children) {
        const resultPath = findPathByKey(node.children, key, currentPath);
        if (resultPath) return resultPath;
      }
    }
    return null;
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  useEffect(() => {
    if (initialCheckedKeys) {
      setCheckedKeys(initialCheckedKeys);
    }
  }, [initialCheckedKeys]);

  return (
    <AntTree
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      treeData={source}
      disabled={readOnly}
      checkStrictly
    />
  );
}
